jQuery(function($) {
  var automatic_table_of_contents_lock = false;
  $('.affiliate-hero.ah-automatic-table-of-contents .ah-automatic-table-of-contents-toggle').on('click', function(e) {
    var speed = 350;
    if(!automatic_table_of_contents_lock) {
      automatic_table_of_contents_lock = true;
      $(this).closest('.ah-automatic-table-of-contents').toggleClass('ah-open');
      $('.ah-toggle-icon', this).toggleClass('ah-transform');
      setTimeout(function() {
        automatic_table_of_contents_lock = false;
      }, 350);
    }
    if($(this).closest('.ah-automatic-table-of-contents').hasClass('ah-open')) {
      $(this).closest('.ah-automatic-table-of-contents').find('ul, ol').slideDown({ duration: speed, easing: "swing" });
    } else {
      $(this).closest('.ah-automatic-table-of-contents').find('ul, ol').slideUp({ duration: speed, easing: "swing" });
    }
  });
});
